/**
 * React.
 */
import React from "react"

/**
 * Global variable(s).
 * 
 * Notes:
 * Set custom global variables for dev/prod environments.
 * 
 * Example Var:
 * GATSBY_ENVIRONMENT="Development"
 * 
 * To add/view value(s) in current file(s),
 * Development: {root}/.env.development
 * Production:  {root}/.env.production
 * 
 * To reference the variable(s) in code use,
 * let foo    = process.env.{VARIABLE-NAME1}
 * const blah = process.env.{VARIABLE-NAME2}
 */
const remoteUrl = process.env.GATSBY_REMOTE_URL

const FeaturedImage = ({ image }) => {

  /* Set default value(s) */
  let img = ''
  let url = remoteUrl + '/wp-content/uploads/'

  /* If we have defined fields */
  if ( typeof( image ) !== 'undefined' && image != null ) {

    /* Set img str */
    img = '<img ' +
          ' class="featured-image" ' + 
          ' src="' + url + image.node.mediaDetails.file + '"' +
          ' alt="' + image.node.altText + '">' +
          '</img>'
  } 
  
  /* Return result */
  return (
    <div dangerouslySetInnerHTML={{ __html: img }} />
  )

}

export default FeaturedImage