/**
 * React.
 */
import React from "react"

/**
 * Gatsby.
 */
import { graphql } from "gatsby"

/**
 * Layout.
 * Used for basic page structure.
 */
import Layout from "../components/layout"

/**
 * SEO.
 * Used for page SEO content.
 */
import Seo from "../components/seo"

/**
 * Featured Images.
 * Used for WordPress Post/Page/CustomPost featured images.
 */
import FeaturedImage from "../components/featuredImages"

/**
 * Global variable(s).
 * 
 * Notes:
 * Set custom global variables for dev/prod environments.
 * 
 * Example Var:
 * GATSBY_ENVIRONMENT="Development"
 * 
 * To add/view value(s) in current file(s),
 * Development: {root}/.env.development
 * Production:  {root}/.env.production
 * 
 * To reference the variable(s) in code use,
 * let foo    = process.env.{VARIABLE-NAME1}
 * const blah = process.env.{VARIABLE-NAME2}
 */
const remoteUrl = process.env.GATSBY_REMOTE_URL

const WpPage = ({ data }) => {

  /* Get wpPage & allWpGfForm variables */
  const {
    wpPage: { title, content, id, seo, featuredImage },
    allWpGfForm: { nodes },
  } = data

  /* Optional: Display the page title of the home page */
  const page_title = ( 'Home' !== title ) ? title : ''

  /**
   * Check if page contains a [Gravity Form] in its content.
   * ( eg. match string [gravityFormTitle=Form Title] in page content )
   */
  let gravityFormStr   = ( content ) ? content.match(/\[(.*?)\]/) : null
  let gravityFormArray = ( gravityFormStr !== null ) ? gravityFormStr[1].split("=") : null
  let gravityFormTitle = ( gravityFormArray !== null ) ? gravityFormArray[1] : 0

  /**
   * Create Gravity Form.
   */
  function addGravityFormByTitle( pageContent, gravityFormTitle, gravityFormData ) {

    /* Set default values */ 
    let formTitle = ''
    let formDescription = ''
    let formFields = []
    let formContent = ''

    /* Loop for all available Gravity Forms */  
    for( var i = 0; i < gravityFormData.length; i++ ) {
      /* Get form data based on form title */
      if ( gravityFormTitle === gravityFormData[i].title ) {
        /* Create form */
        formContent += '<form id="gf_form" name="gf_form" class="gf_form outline: none;">'       
        /* Add form title */
        formTitle = gravityFormData[i].title
        formContent += '<div class="form-title">' + formTitle + '</div>'
        /* Add form description */
        formDescription = gravityFormData[i].description
        formContent += '<div class="form-description">' + formDescription + '</div>'
        /* Add form submitted */
        formContent += '<div id="form_submitted"></div>'
        /* Now we are ready to add all form fields */
        formFields = gravityFormData[i].formFields.nodes
        /* Remove invalid/incorrectly configured fields */
        formFields = formFields.filter( element => {
          if ( Object.keys( element ).length !== 0 ) {
            return true;
          }
          return false;
        });
        /* For all form fields */
        for( var gfId = 0; gfId < formFields.length; gfId++ ) {
          /* Set form field value */
          var formField = ''
          /* Set description value */
          formFields[gfId].description = ( null !== formFields[gfId].description ) ? formFields[gfId].description : ''
          /* Set form field based on type ( assigned by the cssClass value ) */
          switch( formFields[gfId].cssClass ) {
            case 'text':
              var placeholderText = ( null !== formFields[gfId].placeholder ) ? formFields[gfId].placeholder : ''
              formField += '<div class="form-field-container">'
              formField += '<div><span class="form-field-title">' + formFields[gfId].label + '</span><span class="form-error" id="error-' + formFields[gfId].id + '" name="error-' + formFields[gfId].id + '"></span></div>'
              formField += '<div class="description">' + formFields[gfId].description + '</div>'
              formField += '<input type="text" id="' + formFields[gfId].id + '" name="' + formFields[gfId].id + '" placeholder="' + placeholderText + '" class="contact-field" value="">'
              formField += '</div>'
              break
            case 'checkbox':
              formField += '<div class="form-field-container">'
              formField += '<div><span class="form-field-title">' + formFields[gfId].label + '</span><span class="form-error" id="error-' + formFields[gfId].id + '" name="error-' + formFields[gfId].id + '"></span></div>'
              formField += '<div class="description">' + formFields[gfId].description + '</div>'
              for( var choice = 0; choice < formFields[gfId].choices.length; choice++ ) {
                var count = Number( choice + 1 ) 
                formField += '<div>'
                formField += '<input type="checkbox" id="' + formFields[gfId].id + '_' + count + '" name="' + formFields[gfId].id + '_' + count + '" value="' + formFields[gfId].choices[choice].value + '" class="checkbox"> ' + formFields[gfId].choices[choice].text
                formField += '</div>'
              }
              formField += '</div>'
              break
            case 'textarea':
              formField += '<div class="form-field-container">'
              formField += '<div><span class="form-field-title">' + formFields[gfId].label + '</span><span class="form-error" id="error-' + formFields[gfId].id + '" name="error-' + formFields[gfId].id + '"></span></div>'
              formField += '<div class="description">' + formFields[gfId].description + '</div>'
              formField += '<textarea id="' + formFields[gfId].id + '" name="' + formFields[gfId].id + '" placeholder="' + formFields[gfId].label + '" class="contact-field"></textarea>'
              formField += '</div>'
              break
            case 'select':
              formField += '<div class="form-field-container">'
              formField += '<div><span class="form-field-title">' + formFields[gfId].label + '</span><span class="form-error" id="error-' + formFields[gfId].id + '" name="error-' + formFields[gfId].id + '"></span></div>'
              formField += '<div class="description">' + formFields[gfId].description + '</div>'
              formField += '<select id="' + formFields[gfId].id + '" name="' + formFields[gfId].id + '">'
              for( var select = 0; select < formFields[gfId].choices.length; select++ ) {
                formField += '<option value="' + formFields[gfId].choices[select].value + '"> ' + formFields[gfId].choices[select].text + '</div>'
              }
              formField += '</select>'
              formField += '</div>'
              break           
            default:
              formField = '' 
          }
          /* Add form field to form content */
          formContent += formField
        }
        /* Add form submit button */
        formContent += '<div className="d-flex justify-content-center">'
        formContent += '<button type="submit"'
        formContent += '        id="gfsubmit"'
        formContent += '        class="btn btn-outline-success submit-gravity-form"'
        formContent += '        style="width: 280px"'
        formContent += '        onclick="return false;">'
        formContent += '  Submit Form'
        formContent += '</button>'
        formContent += '</div>'
        /* End form */
        formContent += '</form>' 
      }
    }
    /* Return page content ( and Gravity Form content if applicable ) */
    return ( pageContent ) ? pageContent.replace( '[gravityFormTitle=' + gravityFormTitle + ']', formContent ) : ''
  }

  /**
   * Validate Gravity Form Fields.
   */
  function validateGravityFormFields( gravityFormTitle, gravityFormData ) {
    /* Count required fields */
    let formErrors = 0
    /* Set default param(s) for form submittal */
    let formParams = ''
    /* Set form fields array */
    let formFields = []
    /* Loop for all available Gravity Forms */  
    for( var i = 0; i < gravityFormData.length; i++ ) {
      /* Get form data based on form title */
      if ( gravityFormTitle === gravityFormData[i].title ) {
        /* Add form id to formParams */
        formParams = atob( gravityFormData[i].id ) + '&'
        /* Get all form fields */
        formFields = gravityFormData[i].formFields.nodes
        /* Remove invalid/incorrectly configured fields */
        formFields = formFields.filter( element => {
          if ( Object.keys( element ).length !== 0 ) {
            return true;
          }
          return false;
        });
        /* Loop for all form fields */
        for( var gfId = 0; gfId < formFields.length; gfId++ ) {
          /**
           * Text, Textarea, Select
           */
          if ( formFields[gfId].cssClass !== 'checkbox' ) {
            /* Get value of form field */
            var val = ( document.getElementById( formFields[gfId].id ) !== null ) ? document.getElementById( formFields[gfId].id ).value : ''
            /* if form field is required and there's no value */
            if ( formFields[gfId].isRequired && ! val ) {
              /* Show error notice */
              document.getElementById( 'error-' + formFields[gfId].id ).innerHTML = 'Required'
              formErrors = formErrors + 1
            } else {
              /* Reset error notice */
              if ( document.getElementById( 'error-' + formFields[gfId].id ) ) {
                document.getElementById( 'error-' + formFields[gfId].id ).innerHTML = ''
              }
              /* Add param to formParam */
              formParams = formParams + 'input_' + formFields[gfId].id + '=' + val + '&'
            }
          }
          /**
           * Checkbox
           */
          if ( formFields[gfId].cssClass === 'checkbox' ) {
            /* Set default ticked value */
            var checkboxTicked = false;
            /* Loop for all checkboxes */
            document.querySelectorAll( '.checkbox' ).forEach( function( elem ) {  /* eslint-disable-line */
              /* If checkbox is ticked */
              if( true === elem.checked ) {
                checkboxTicked = true;
                /* Add value(s) to formParams */
                formParams = formParams + 'input_' + elem.id + '=' + elem.value + '&'
              }
            });
            /* If checkbox is required and isn't ticked */ 
            if ( false === checkboxTicked ) {
              /* Show error notice */
              document.getElementById( 'error-' + formFields[gfId].id ).innerHTML = 'Required'
              formErrors = formErrors + 1
            } else {
              /* Reset error notice */
              document.getElementById( 'error-' + formFields[gfId].id ).innerHTML = ''
            }
          }
        }
      }
    }
    /* If we don't have any formErrors, remove the last character from the
       formParams value ( which is a & character ) and return the formParams */
    return ( ! formErrors ) ? formParams.slice( 0, -1 ) : ''
  }

  /**
   * Submit Gravity Form.
   * ( include Akismet validation ).
   */ 
  if ( typeof document !== 'undefined' ) {
    /* Onclick event */
    document.addEventListener( 'click', function( e ) {
      /* If onclick was to submit gravity form */
      if ( e.target.matches( '.submit-gravity-form' ) ) {
        /* Validate form fields and get the form params to send to db */
        var formParams = validateGravityFormFields( gravityFormTitle, nodes )
        /* Send the formParams to the db */
        if ( formParams ) {
          /* At least one formParams must have a value, count total */
          var formParamsCount = -1 /* Form name is the default formParams value, so deduct base value by -1 */
          var formParamsArray = formParams.split("&");
          for( var i = 0; i < formParamsArray.length; i++ ) {
              var val = formParamsArray[i].split( '=' );
              if ( '' !== val[1] ) {
                formParamsCount++
              }
          }
          /* At least one formParams must have a value, show notice */
          if ( formParamsCount <= 0 ) {
            document.getElementById( 'form_submitted' ).innerHTML = '<p style="color:red;">At least one field must be filled out</p>';
            return;
          }            
          /* Get the formId from formParams str */
          var formId = ( formParamsArray[0] ) ? formParamsArray[0].replace( 'gf_form:', '' ) : '' 
          /* Update formParams gf_form value to valid format for Akismet */
          formParams = formParams.replace( 'gf_form:' + formId, 'gf_form=' + formId );
          /* Set Akismet url to send formParams to */
          let akismetUrl = remoteUrl + '/?gatsby_akismet=true';
          /* Send the formParams to Akismet */
          fetch( akismetUrl, {
            method: 'POST',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: formParams
          })
          .then( res => res.json() )
          .then( data => {
            /* If Akismet flagged the formParams as spam, do not continue */
            if ( 'spam' === data.result ) {
              document.getElementById( 'form_submitted' ).innerHTML = '<p style="color:red;">This form content is flagged as spam and will not be submitted.</p>';
              return;
            } 
            /* Set Gravity Form url to send formParams to */
            let url = remoteUrl + '/wp-json/gf/v2/forms/' + formId + '/submissions';
            /* Send the formParams to Wordpress */
            fetch( url, {
              method: 'POST',
              headers: {'Content-Type':'application/x-www-form-urlencoded'},
              body: formParams
            })
            .then( res => res.json() )
            .then( data => {
              /* On response, what should we do? */
              if ( ! data.is_valid ) {
                document.getElementById( 'form_submitted' ).innerHTML = '<p><strong>' + data.validation_messages[1] + '</strong></p>';
              } else {
                document.getElementById( 'form_submitted' ).innerHTML = '<p><strong>' + data.confirmation_message + '</strong></p>';
              }
              /* Reset all form fields */
              if ( data.is_valid ) {
                document.getElementById( 'gf_form' ).reset();
              }
            })
            .catch(error => {
              console.log( error )
            }) 

          })
          .catch(error => {
            console.log( error )
          })           
        }            
      }
    });
  }

  /* Set pageContent to default */
  let pageContent = content

  /* Update pageContent to include Gravity Forms */
  pageContent = addGravityFormByTitle( pageContent, gravityFormTitle, nodes ) 

  /* Return the page content */
  return (

    <Layout className={`post-${id}`}>

      {/* SEO details */}
      <Seo title={title} id={id} yoastseo={seo} />

      {/* Featured image */}
      <FeaturedImage image={featuredImage} />

      {/* Page title */}
      <h1>{page_title}</h1> 

      {/* Page content */}
      <div dangerouslySetInnerHTML={{ __html: pageContent }} />

    </Layout>      
  )

}

/* Get wpPage and allWpGfForm data */
export const query = graphql`
  query($id: String) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      featuredImage {
        node {
          mediaDetails {
            file
          }
          altText
        }
      } 
      seo {
        title
        metaDesc
        canonical
        opengraphDescription
        opengraphTitle
        opengraphUrl
        opengraphSiteName
        opengraphModifiedTime
        opengraphImage {
          mediaItemUrl
          mediaDetails {
            height
            width
          }
          mimeType
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
        schema {
          articleType
          pageType
          raw
        }
      } 
    }
    allWpGfForm {
      nodes {
        id
        title
        description
        formFields {
          nodes {
            ... on WpTextField {
              id
              label
              description
              cssClass
              isRequired
              placeholder
            }
            ... on WpCheckboxField {
              id
              label
              choices {
                text
                value
              }
              cssClass
              isRequired
            }
            ... on WpTextAreaField {
              id
              label
              maxLength
              description
              cssClass
              isRequired
            }
            ... on WpSelectField {
              id
              label
              description
              choices {
                text
                value
              }
              cssClass
              isRequired
            }
          }
        }
      }
    }
  }
`

export default WpPage